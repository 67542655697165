import classNames from "classnames";
import React, { FC } from "react";
import { twMerge } from "tailwind-merge";

interface RatingProps {
  rate: number;
  reviews?: number | null;
  size?: "xs" | "sm";
  className?: string;
  variant?: "default" | "stars";
  isDisabled?: boolean;
  count?: number | null;
  onClick?: () => void;
  alwaysShowDecimals: boolean;
}

const sizes = {
  xs: {
    text: "text-xs",
    star: "w-3 h-3",
  },
  sm: {
    text: "text-sm",
    star: "w-5 h-5",
  },
};

type StarIcon = "star" | "starHalf" | "starEmpty";

interface StarProps {
  icon: StarIcon;
  className?: string;
}

const getStars = (
  rate: number,
  count: number,
  isDisabled: boolean,
  starIndex = 1
): StarProps[] => {
  if (starIndex > count) {
    return [];
  }

  let starType = "starEmpty" as StarIcon;
  if (starIndex <= rate) {
    starType = "star";
  } else if (starIndex > rate && rate > starIndex - 1) {
    starType = "starHalf";
  } else {
    starType = "starEmpty";
  }

  const variant: StarProps = {
    icon: starType,
    className: isDisabled
      ? "text-search_border"
      : starType === "star"
      ? "text-star"
      : "text-search_border",
  };

  return [variant, ...getStars(rate, count, isDisabled, ++starIndex)];
};

const Icon: FC<{ icon: StarIcon; className?: string }> = ({
  icon,
  className = "",
}) => {
  if (icon === "starEmpty") {
    return (
      <svg
        className={`w-4 h-4 ${className}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 0 0 .95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 0 0-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 0 0-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 0 0-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 0 0 .951-.69l1.07-3.292z" />
      </svg>
    );
  }
  if (icon === "starHalf") {
    return (
      <svg
        className={`w-4 h-4 ${className}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <defs>
          <linearGradient id="grad">
            <stop offset="50%" stopColor="rgba(255,175,44)" />
            <stop offset="50%" stopColor="currentColor" />
          </linearGradient>
        </defs>
        <path
          fill="url(#grad)"
          d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
        />
      </svg>
    );
  }

  return (
    <svg
      className={`w-4 h-4 ${className}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="rgba(255,175,44)"
    >
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 0 0 .95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 0 0-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 0 0-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 0 0-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 0 0 .951-.69l1.07-3.292z" />
    </svg>
  );
};

export const Rating: FC<RatingProps> = ({
  rate,
  count = 5,
  reviews,
  size = "sm",
  className = "",
  isDisabled = false,
  variant = "default",
  alwaysShowDecimals,
  onClick,
}) => {
  const classes = classNames(
    `flex items-center flex-wrap`,
    className,
    sizes[size].text
  );

  const stars = getStars(rate, count as number, isDisabled);

  return (
    <div
      className={twMerge(`${onClick && "cursor-pointer"}`, classes)}
      onClick={onClick}
    >
      {variant === "default" && (
        <>
          <div className="flex flex-row">
            {stars.map((star, index) => (
              <Icon
                key={index}
                icon={star.icon}
                className={`${star.className} ${sizes[size].star}`}
              />
            ))}
          </div>
        </>
      )}

      {variant === "stars" && (
        <div className="flex items-center align-middle">
          {stars.map((star, index) => (
            <Icon
              key={`rating-${index}`}
              icon={star.icon}
              className={`${star.className} ${sizes[size].star}`}
            />
          ))}
          {rate > 0 && (
            <span className="align-middle font-medium text-xs ml-1 mr-2 whitespace-nowrap">
              {`${alwaysShowDecimals ? rate.toFixed(2) : rate} / ${count}`}
            </span>
          )}
        </div>
      )}

      {!!reviews && (
        <div>
          <span className="align-middle text-xs truncate font-bold underline text-blue-700">
            {`(${reviews} reviews)`}
          </span>
        </div>
      )}
    </div>
  );
};
